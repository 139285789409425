// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
  background: #ecede7;
  font-family: 'Roboto', sans-serif;
  color: black;
  font-size: 15px;
}

$vredemans_orange: #f17c21;
$gray: #666666;
$breakpoint-lg-big: 1420px;

.full-width {
  display: block;
  width: 100%;
}

.header {
  background-color: white;
  background-image: url('../images/stripes-background-header.png');
  background-repeat: repeat-x;
  background-position: bottom center;
  border: 3px solid white;

  .logo {
    margin-top: 20px;
  }

  .bovag {
    float: right;
    margin-top: 70px;

    @media(max-width: $screen-xs-max){
      position: absolute;
      width: 50px;
      top: -10px;
      margin-top: 0;
      right: 20px;
    }
  }

  .auto-header {
    @media(min-width: $screen-md-min){
      margin-top: 50px;
    }

    .auto-header-absolute-wrapper img {
      margin-bottom: -75px;
    }
  }

}

.gray-bar {
  background: #ecede7;
  height: 100px;
  margin-top: 3px;
}

.mainmenu {
  display: block;
  width: 100%;
  background: $vredemans_orange;
  border-bottom: 3px solid #cccccc;

  .navbar-toggle{
    background: $vredemans_orange;
    margin-right: 0;

    .icon-bar{
      background: white;
    }
  }
  .navbar-brand{
    color: white;
    margin-top: 5px;
  }
  ul {
    list-style: none;
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;

    @media(min-width: $screen-xs-max){
      text-align: center;
    }

    li {

      padding-top: 5px;
      padding-bottom: 5px;
      &.active{
        @media(min-width: $screen-xs-max){
          background-image: url('../images/stripes-mainmenu-background.png');
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      @media(min-width: $screen-xs-max) {
        display: inline-block;
        float: none;
      }

      a {
        background-color: $vredemans_orange;
        color: white;
        padding: 2px 40px;

        @media(max-width: $screen-xs-max){
          padding-left: 0;
          width: 100%;
        }
        @media(min-width: $screen-xs-max) and (max-width: $screen-md-max){
          padding: 5px 10px;
        }

        font-size: 20px;
        display: block;
        @media(max-width: $screen-xs-max){
          display: inline-block;
        }

        &:hover, &:focus{
            background: #f88b36;
        }
      }
    }
  }
  .navbar-collapse{
    padding: 0;
  }
}

.occasion-tile {
  $padding: 5px;
  padding: $padding;
  background: white;
  display: block;
  float: left;
  margin-top: 30px;

  .occasion-details {
    padding: 10px 10px 0 10px;

    .title {
      font-size: 20px;
    }

    ul.specs {
      list-style: none;
      margin: 0;
      padding: 0;
      float: left;

      li {
        float: left;
        padding: 3px;
        color: $gray;
        line-height: 15px;

        &:after {
          content: " \\ ";
          color: $vredemans_orange;
        }
      }
    }

    .price {
      font-size: 22px;
      color: $vredemans_orange;
    }
  }

  a.offer {
    color: $gray;
    top: 10px;
    position: relative;
    margin-left: 10px;
  }

  .nap-price {
    padding: 10px 10px;
  }

  .to-details {

    a {
      position: relative;
      background-color: $vredemans_orange;
      display: block;
      padding: 10px 20px;
      color: white;
      margin-right: -$padding;

      .skew {
        height: 100%;
        display: block;
        margin-left: -42px;
        top: 0;
        width: 30px;
        background-image: url('../images/skew-left.svg');
        position: absolute;
      }
    }
  }
}

a.black-bar{
  background-color: black;
  background-image: url('../images/stripes-bar.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 30px;
  color: white;
  text-align: right;
  padding: 10px 10px 10px 10px;
  display: block;
  margin: 20px 0;
}

.stripes-bottom-3{
  background-image: url('../images/stripes-black-3perc.png');
  background-repeat: repeat-x;
  background-position: bottom;
}

h1{
  font-size: 25px;
  margin: 0;
  padding: 0;
}

.pane-title{
  padding: 10px;
}
h2{
  margin: 0;
  padding: 0;
  font-size: 21px;
}
.backgr-orange{
  background-color: $vredemans_orange;
}
.backgr-white{
  background-color: white;
}
.backgr-black{
  background-color: black
}
.pane-title.backgr-orange, .pane-title.backgr-black{
  color: white;
}


.pane-content{
  background-color: white;
  border: 1px solid #999999;
  border-top: none;
  margin-bottom: 20px;
  padding: 25px;
  background-position: 90% 20px;
  background-size: 45px;
  background-repeat: no-repeat;

  &.pane-content.background-icon{
    padding: 25px 100px 25px 25px;
  }
}
.background-icon.location-icon{
  background-image: url('../images/location-icon.svg');
}
.background-icon.clock-icon{
  background-image: url('../images/clock-icon.svg');
  background-size: 60px;
}
p{
  margin: 0 0 38px 0;
  padding: 0;

  &:last-of-type{
    margin: 0;
  }
}
.flexcolumns{
  display: flex;
  flex-directon: row;

  .flexcolumn-1{
    flex: 1;
  }
  .flexcolumn-2{
    flex: 2;
  }
  .centered{
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
.section-padding-top{
  padding-top: 20px;
}
.section-padding-bottom{
  padding-bottom: 20px;
}
.like-us-fb{
  color: white;
  padding: 20px 0 20px 60px;
  background-image: url('../images/icon-fb.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 35px;

  @media(min-width: $screen-md-min){
    top: 50px;
  }
  @media(max-width: $screen-md-min){
    top: 24px;
  }
  @media(max-width: $screen-xs-max){
    top: 0;
  }
  position: relative;
  @media(max-width: $screen-xs-max){
    display: inline-block;
  }
  &:hover{
    color: white;
  }
}
.logo-footer{
  @media(max-width: $screen-xs-max){
    margin-top: 30px;
  }
}
$filtermenu-width: 400px;

.filtermenu{
  $filtermenu-padding: 30px;
  width: $filtermenu-width;
  @media(max-width: 767px) {
    width: 100%;
  }
  height: 100%;
  position: fixed;
  display: block;
  z-index: 3;
  background: white;
  transition: 1s;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;

  .close-filters{
    position: absolute;
    right: $filtermenu-padding;
    top: $filtermenu-padding;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: block;
    background-size: 100%;
    background-image: url('../images/close.svg');
  }

  .content{
    position: relative;
    padding: $filtermenu-padding;

    .filtername{
      font-size: 15px;
      font-weight: bold;
      clear: both;
      margin: 0 0 5px 0;
    }

    ul{
      list-style: none;
      padding: 0;
      margin: 0 0 15px 0;

      &.short{
        li:nth-child(n+5){
          display: none;
        }
        li.toggle-short{
          display: block;
        }
      }

      li{
        margin: 0;
        padding: 0;
        color: black;

        a{
          color: $vredemans_orange;
          padding-left: 20px;
          background-image: url('../images/arrow-right.svg');
          background-repeat: no-repeat;
          background-size: 15px;
          background-position: left center;
        }
        &.toggle-short{
          cursor: pointer;
          display: block;
          background: #ecede7;
          padding: 5px 5px 5px 10px;
          margin-top: 10px;
        }
      }
    }
  }
}

.filtermenu.closed{
  left: -$filtermenu-width;
  @media(max-width: 767px) {
    left: -100%;
  }
}
.overlay{
  background: black;
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  z-index: 1;
  transition: 0.5s;
}
.overlay.closed{
  opacity: 0;
  transition: 0.5s;
}
ul.slick-slider{
  padding: 0 !important;
  margin: 0;

  li.slick-slide{
    margin: 0 15px;
  }
}

.slick-arrow{
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  top: 50%;
  @media(max-width: $screen-xs-max){
    top: 100px;
  }
  cursor: pointer;
  background-repeat: no-repeat;
  z-index: 2;

  @media(max-width: $breakpoint-lg-big){
    background-color: white;
    background-position: center !important;
    background-size: 15px;
  }

  &.left{
    left: -50px;
    @media(max-width: $breakpoint-lg-big){
      left: 0;
    }
    background-image: url('../images/arrow-big-left.svg');
    background-position: center right;

  }

  &.right{
    right: -50px;
    @media(max-width: $breakpoint-lg-big){
      right: 0;
    }
    background-image: url('../images/arrow-big-right.svg');
    background-position: center left;
  }
}
@media(max-width: $screen-xs-max) {
  .mobile-margin-top {
    margin-top: 20px;
  }
  .text-center-xs {
    text-align: center;
  }
}